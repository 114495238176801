import { Controller } from "stimulus"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock"

export default class extends Controller {
  static targets = ["content", "wrapper"]

  connect() {
    disableBodyScroll(this.element)
  }

  disconnect() {
    enableBodyScroll(this.element)
  }
}
