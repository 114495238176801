import ApplicationController from "./application_controller.coffee"
import debounce from "lodash/debounce"
import animateScrollTo from "animated-scroll-to"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends ApplicationController
  @targets = ["videos"]

  @property "videos",
    get: -> @videosTarget.querySelectorAll('video')

  @property "height",
    get: -> @element.scrollHeight

  @property "heightVar",
    set: (val) -> @element.style.setProperty("--chapter-module-height", val+"px")

  @property "topOffset",
    get: -> @element.getBoundingClientRect().top

  @property "elementScrollPosition",
    get: -> (@element.nextElementSibling.getBoundingClientRect().top + document.documentElement.scrollTop) - @element.getBoundingClientRect().height

  hasStartedOnce = false

  connect: ->
    @heightVar = @height

    @windowResizeHandler = @handleWindowResize.bind(@)
    window.addEventListener("resize", @windowResizeHandler)

    # @disableLoopVideos()

    @playPauseVideos(false)

    setTimeout (=>
      @heightVar = @height
      @playPauseVideos(false)

      if @hasVideosTarget
        @onIntersectionHandler = @onIntersection.bind(@)
        @observer = new IntersectionObserver(@onIntersectionHandler, { threshold: 0, rootMargin: '0% 0% -100% 0%' })
        @observer.observe(@element)
      ), 250

  disconnect: ->
    window.removeEventListener("resize", @windowResizeHandler) if @windowResizeHandler

  handleWindowResize: (entries) ->
    @heightVar = @height

  onClickScrollIntoView: ->
    if @topOffset > 100
      animateScrollTo(@elementScrollPosition, {
                        cancelOnUserAction: false,
                        speed: 250
                     })

  onIntersection: (entries, observer) ->
    for entry in entries
      if entry.isIntersecting && !hasStartedOnce
        @playPauseVideos(true)
        hasStartedOnce = true

  disableLoopVideos: (bool) ->
    return unless @hasVideosTarget
    for video in @videos
      video.loop = false

  playPauseVideos: (bool) ->
    return unless @hasVideosTarget
    for video in @videos
      if bool == true
        video.play()
      else
        if !hasStartedOnce
          video.pause()
        else
          video.remove()
