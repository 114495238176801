# @see https://www.npmjs.com/package/inview instead

import { default as WebModule__Content } from 'models/modulor/web_module/content'
import ApplicationController from "./application_controller.coffee"

export class HetHemHeader extends WebModule__Content
#   @defaults =
#     debug: false
#     name: 'HetHemHeaderModule'
#
#   @selector: ".het_hem_header_module"
#
#   on_init: ->
#     console.log "A"
#
# HetHemHeader.register()
