import "src/application.css"

import 'modulor'

import 'plugins'
import 'shared'

import 'components'
import 'initializers'
import 'models'

import {} from 'jquery-ujs'

import Turbolinks from 'turbolinks'
Turbolinks.start()

import MutationObserver from 'lib/modulor/mutation_observer'
MutationObserver.start()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("../controllers", true, /\.(js|coffee)$/)
application.load(definitionsFromContext(context))
