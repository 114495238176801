import ApplicationController from "./application_controller.coffee"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends ApplicationController
  @property "isSearch",
    get: -> @element.hasAttribute("data-search")
    set: (val) -> @element.toggleAttribute("data-search")

  onClickToToggleSearch: (event) ->
    @isSearch = !@isSearch
    @element.querySelector("input[type=search]").focus() if @isSearch

  onKeyUp: (event) ->
    return unless event.key == "Escape"
    return unless @isSearch
    @isSearch = false
