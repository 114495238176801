import { Controller } from "stimulus"
import pillButtonStyles from "shared/utilities/pill.module.css.json"

export default class extends Controller {
  connect() {
    this.scrollToCurrentButton()
  }

  scrollToCurrentButton() {
    if (this.isOverflowing)
      this.element.scrollLeft = this.currentButton.offsetLeft
  }

  isOverflowing() {
    return (
      this.element.scrollHeight > this.element.clientHeight ||
      this.element.scrollWidth > this.element.clientWidth
    )
  }

  get currentButton() {
    return this.element.querySelector(
      `.${pillButtonStyles.default.split(/\s+/)[0]}`
    )
  }
}
