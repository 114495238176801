import { CarouselController } from "./carousel_controller"
import styles from "components/date_picker_component.module.scss.json"
import weekStyles from "components/date_picker_component/week_component.module.scss.json"
import dateStyles from "components/date_picker_component/date_component.module.scss.json"
import { parse } from "date-fns"

export default class extends CarouselController {
  connect() {
    super.connect()

    this.setInitiallyPickedDates()
    this.slideToCurrentlyPickedMonth()
    this.dispatchChangeEvent()
  }

  onHashChange(event) {
    if (!this.isHashMethod) return
    this.setPickedDatesFromHash()
    this.slideToCurrentlyPickedMonth()
  }

  resize() {
    super.resize()
    this.dispatchChangeEvent()
  }

  setInitiallyPickedDates() {
    if (this.isHashMethod && window.location.hash) {
      this.setPickedDatesFromHash()
    } else if (this.isWeekPicker && this.firstPickableWeek) {
      this.firstPickableWeek
        .querySelectorAll(this.dateSelector)
        .forEach(date => date.classList.add(dateStyles.picked))
    } else {
      // console.warn("TODO")
    }
  }

  setPickedDatesFromHash() {
    this.dates.forEach(date => {
      date.classList.toggle(
        dateStyles.picked,
        date.getAttribute("href") === window.location.hash
      )
    })
  }

  pick(event) {
    if (this.isHashMethod) {
      event.preventDefault()
      event.stopImmediatePropagation()
      this.pickFromHash(event)
    }
  }

  pickFromHash(event) {
    const hash = event.currentTarget.getAttribute("href")
    history.replaceState({ modal: true }, "", hash)
    window.dispatchEvent(new HashChangeEvent("hashchange"))
  }

  slideToCurrentlyPickedMonth() {
    if (!this.flickity && !this.currentlyActiveMonth) return
    const index = Array.prototype.indexOf.call(
      this.flickity.getCellElements(),
      this.currentlyActiveMonth
    )
    if (this.flickity.selectedIndex != index) {
      this.flickity.selectCell(index)
    }
  }

  // Weeks

  get firstPickableWeek() {
    return [...this.weeks].find(week => {
      return (
        week.classList.contains(this.pickableWeekClass) &&
        !week.classList.contains(this.pastWeekClass)
      )
    })
  }

  get weeks() {
    return this.element.querySelectorAll(this.weekSelector)
  }

  get weekSelector() {
    return this._selectorFor(this.weekClass)
  }

  get weekClass() {
    return weekStyles.root.split(/\s+/)[0]
  }

  get pickableWeekClass() {
    return weekStyles.pickable.split(/\s+/)[0]
  }

  get pastWeekClass() {
    return weekStyles.past.split(/\s+/)[0]
  }

  // Dates

  getMonthForDate(date) {
    return date.closest(this.cellSelector)
  }

  get firstPickableDate() {
    return [...this.dates].find(element => {
      return (
        element.classList.contains(this.pickableDateClass) &&
        !element.classList.contains(this.pastDateClass)
      )
    })
  }

  get firstVisibleDate() {
    return this.visibleDates[0]
  }

  get lastVisibleDate() {
    return this.visibleDates.slice(-1)[0]
  }

  get visibleDates() {
    return this.selectedMonths
      .map(month => {
        return [
          ...month.querySelectorAll(
            `.${this.dateClass}:not(.${this.outOfMonthDateClass})`
          )
        ]
      })
      .flat(Infinity)
  }

  get dates() {
    return this.element.querySelectorAll(this.dateSelector)
  }

  get currentlyActiveDates() {
    if (!window.location.hash) return this.lastDate
    return this.element.querySelectorAll(`[href|="${window.location.hash}"]`)
  }

  get dateSelector() {
    return this._selectorFor(this.dateClass)
  }

  get outOfMonthDateClass() {
    return dateStyles.out_of_month.split(/\s+/)[0]
  }

  get pickableDateClass() {
    return dateStyles.pickable.split(/\s+/)[0]
  }

  get pastDateClass() {
    return dateStyles.past.split(/\s+/)[0]
  }

  get dateClass() {
    return dateStyles.root.split(/\s+/)[0]
  }

  get currentlyVisibleDateRange() {
    return [
      parse(this.firstVisibleDate.dataset.date),
      parse(this.lastVisibleDate.dataset.date)
    ]
  }

  // Months

  get selectedMonths() {
    return this.currentlySelectedMonthAndSiblings.slice(
      0,
      this.numberOfVisibleMonths
    )
  }

  get numberOfVisibleMonths() {
    return [...this.months].filter(month => this.monthIsVisible(month)).length
  }

  get currentlySelectedMonth() {
    this.flickity.selectedElement
  }

  get currentlySelectedMonthAndSiblings() {
    let selectedMonths = []
    let month = this.flickity.selectedElement

    while (month) {
      if (
        month !== this.currentlySelectedMonth &&
        month.nodeType === Node.ELEMENT_NODE
      )
        selectedMonths.push(month)
      month = month.nextElementSibling || month.nextSibling
    }

    return selectedMonths
  }

  get currentlyActiveMonth() {
    if (!this.currentlyActiveDates) return
    return this.getMonthForDate(this.currentlyActiveDates[0])
  }

  get months() {
    const monthSelector = `.${this.monthClass}`
    return this.element.querySelectorAll(monthSelector)
  }

  get monthClass() {
    return styles.month.split(/\s+/)[0]
  }

  // Flickity

  get initialIndex() {
    if (this.firstPickableDate) {
      const month = this.getMonthForDate(this.firstPickableDate)
      return [...this.months].indexOf(month)
    } else {
      if (!this.flickity) return
      return this.flickity.cells.length - 1
    }
  }

  get cellSelector() {
    return this._selectorFor(this.monthClass)
  }

  // Options

  get isParamMethod() {
    return this.data.get("method") === "param"
  }

  get isHashMethod() {
    return this.data.get("method") === "hash"
  }

  get isDatePicker() {
    return this.data.get("pick") === "date"
  }

  get isWeekPicker() {
    return this.data.get("pick") === "week"
  }

  // Util

  _selectorFor(klass) {
    return `.${klass}`
  }

  monthIsVisible(month) {
    if (!month) return false
    return this.isColliding(month, this.cellsTarget)
  }

  isColliding(a, b) {
    const aRect = a.getBoundingClientRect()
    const bRect = b.getBoundingClientRect()

    return !(
      aRect.top + aRect.height < bRect.top ||
      aRect.top > bRect.top + bRect.height ||
      aRect.left + aRect.width < bRect.left ||
      aRect.left > bRect.left + bRect.width
    )
  }
}
