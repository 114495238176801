import { default as WebModule__Container } from 'models/modulor/web_module/container'

export default class Content extends WebModule__Container
  @defaults =
    debug: false
    name: 'ToolbarModule__Content'

  @selector: ".toolbar_module__content"

  on_init: ->
    @set_toolbar_height_var()

  on_load: -> @set_toolbar_height_var()
  on_resize: -> @set_toolbar_height_var()

  set_toolbar_height_var: ->
    height = @$element.outerHeight()
    if height > 0
      $('html').css("--toolbar-height", "#{height}px")

Content.register()
