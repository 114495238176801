import { Controller } from "stimulus"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"

import chapter_header_module_styles from "models/web_modules/chapter_header_modules/chapter_header_module.module.scss.json"
import het_hem_header_module_styles from "models/web_modules/het_hem_header_modules/het_hem_header_module.module.scss.json"
import modal_component_styles from "components/modal_component.module.scss.json"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends Controller
  @targets = ["hetHemPage", "chapterPage"]

  @property "scrollSpeed",
    get: -> 500

  @property "state",
    get: -> @element.getAttribute("data-state")
    set: (val) -> @element.setAttribute("data-state", val)

  @property "transition",
    get: -> @element.getAttribute("data-transition")
    set: (val) -> if val then @element.setAttribute("data-transition", val) else @element.removeAttribute("data-transition")

  @property "cover",
    get: -> @element.getAttribute("data-cover")
    set: (val) -> if val then @element.setAttribute("data-cover", val) else @element.removeAttribute("data-cover")

  @property "$hetHemPage",
    get: -> $(@hetHemPageTarget)

  @property "$chapterPage",
    get: -> $(@chapterPageTarget)

  @property "chapterPageMarginTop",
    # get: -> parseInt $(@chapterPageTarget.firstElementChild).css("margin-top")
    get: -> @chapterPageTarget.firstElementChild.offsetTop

  @property "$chapterHeaderModule",
    get: -> $("." + chapter_header_module_styles.header.split(" ")[0])

  @property "$tickerModule",
    get: -> $(".ticker_module__content")

  @property "isChapterPageActive",
    get: -> $(".chapter_page__is_active").length > 0

  @property "chapterHeaderObserver",
    get: -> @$_chapterHeaderObserver ||= new IntersectionObserver(@handleChapterHeaderObserver.bind(this), { threshold: 1 })

  connect: ->
    setTimeout (=>
      @transitionToChapter(false, true)
      @chapterHeaderObserver.observe(@$chapterHeaderModule[0])
    ), 500

  disconnect: -> @chapterHeaderObserver.disconnect()

  handleClickOnBody: (e) ->
    return unless @state == "chapter"
    return if $(e.target).closest(".chapter_page").length > 0
    return if $(e.target).closest(".menu_module").length > 0
    return if $(e.target).closest(".#{het_hem_header_module_styles.language_switcher.split(' ')[0]}").length > 0
    @transitionToHetHem()

  handleChapterHeaderObserver: (entries, observer) ->
    for entry in entries
      isCoveringHetHem = entry.boundingClientRect.top < entry.rootBounds.top
      if isCoveringHetHem
        @cover = "chapter"
      else
        @cover = null

  handleClickOnChapter: (e) ->
    e.preventDefault()
    if @state == "chapter"
      @transitionToHetHem()

    if @state == "het-hem" && @isChapterPageActive
      $("body, html").animate(
        { scrollTop: @$hetHemPage.outerHeight() },
        @scrollSpeed
      )

    unless @isChapterPageActive
      @transitionToChapter()

  handleClickOnHetHem: (e) ->
    e.preventDefault()
    $("html, body").animate { scrollTop: 0 }, @scrollSpeed

  handleClickForTransitionToHetHem: (e) ->
    @transitionToHetHem()

  handleClickForTransitionToChapter: (e) ->
    path = e.currentTarget.getAttribute("href").substr(4)
    return unless $(".modulor_page[data-path='#{path}']").length > 0
    e.preventDefault()
    @transitionToChapter()

  handleIntersection: (e) ->
    return unless @state == "chapter"
    return if e.detail.isIntersecting
    @transitionToHetHem()

  transitionToHetHem: ->
    return if @state == "het-hem"
    @transition = "het-hem"
    @$chapterPage.animate(
      { scrollTop: 0 },
      @scrollSpeed,
      =>
        clearAllBodyScrollLocks()
        @transition = undefined
        @state = "het-hem"
    )

  transitionToChapter: (animate = true, initial = false) ->
    return if @state == "chapter"
    @transition = "chapter"
    @state = "chapter"
    if initial
      scrollTop = window.innerHeight/2
    else
      scrollTop = Math.abs(@$chapterHeaderModule.innerHeight() - (window.innerHeight - @chapterPageMarginTop))
    # console.log scrollTop
    @$chapterPage.animate(
      { scrollTop: scrollTop },
      (if animate then @scrollSpeed else 1),
      =>
        @transition = undefined
        disableBodyScroll(@chapterPageTarget)
    )
