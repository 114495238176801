import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this._timeout = window.setTimeout(() => this.update(), this.updateInterval)
  }

  disconnect() {
    window.clearTimeout(this._timeout)
  }

  update() {
    fetch(this.updateUrl).then((response) => {
      return response.text()
    }).then((html) => {
      const parser = new DOMParser()
	    const doc = parser.parseFromString(html, "text/html")
      const newElement = doc.body.firstChild
      this.element.parentNode.replaceChild(newElement, this.element)
    }).catch(function (err) {
    	// console.warn("Unable to fetch opening hours.", err);
    })
  }

  get updateUrl() {
    return this.data.get("updateUrl")
  }

  get updateInterval() {
    return this.data.get("updateInterval")
  }
}
