import { Controller } from "stimulus"
import styles from "components/video_playlist_component.module.scss.json"
import videoComponentStyles from "components/video_component.module.scss.json"

export default class extends Controller {
  static targets = ["video", "template"]

  initialize() {
    this.setCurrentVideoTarget()
  }

  play() {
    this.currentVideoComponentController.play()
  }

  next() {
    if (this.templateTargets.length <= 1) return
    if (this.nextIndex == null) return
    this.index = this.nextIndex
    this.setCurrentVideoTarget()
    this.currentVideoComponentController.play()
    if (this.previousVideoTarget) {
      this.previousVideoTarget.remove()
    }
  }

  setCurrentVideoTarget() {
    this.videoTargets.forEach((el, i) => {
      el.classList.toggle(styles.current, el.dataset.index == this.index)
    })
  }

  queueNext(event) {
    if (this.nextIndex == undefined) return
    this.queue(this.nextIndex)
  }

  queue(index) {
    if (this.getVideoTargetForIndex(index)) return
    const template = this.templateTargets[index]
    if (!template) return
    const clone = document.importNode(template.content, true)
    if (!clone) return
    const video = clone.querySelector(`.${styles.video}`)
    this.element.prepend(clone)
  }

  onTimeupdate(event) {
    if (event.detail.timeLeft < this.queuingTime) {
      this.queueNext()
    }
  }

  getVideoTargetForIndex(index) {
    return this.videoTargets.find(elem => parseInt(elem.dataset.index) == index)
  }

  get currentVideoComponentController() {
    if (!this.currentVideoTarget) return
    let videoComponent = this.currentVideoTarget.querySelector(
      `.${videoComponentStyles.root}`
    )
    return this.application.getControllerForElementAndIdentifier(
      videoComponent,
      "video-component"
    )
  }

  get currentVideoTarget() {
    return this.getVideoTargetForIndex(this.index)
  }

  get previousVideoTarget() {
    return this.getVideoTargetForIndex(this.previousIndex)
  }

  get nextVideoTarget() {
    return this.getVideoTargetForIndex(this.nextIndex)
  }

  get index() {
    return parseInt(this.data.get("index")) || 0
  }

  set index(value) {
    this.data.set("index", value)
  }

  get nextIndex() {
    if (this.loop && this.isLastIndex) return 0
    if (this.isLastIndex) return
    return this.index + 1
  }

  get previousIndex() {
    if (this.loop && this.isFirstIndex) return this.lastIndex
    if (this.isFirstIndex) return
    return this.index - 1
  }

  get lastIndex() {
    return this.templateTargets.length - 1
  }

  get queuingTime() {
    return parseInt(this.data.get("queuingTime")) || 4
  }

  get isLastIndex() {
    return this.index == this.lastIndex
  }

  get isFirstIndex() {
    return this.index == 0
  }

  get loop() {
    return this.data.get("loop") === "true" || true
  }
}
