import { Controller } from "stimulus"
import animateScrollTo from "animated-scroll-to"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends Controller

  scroll: (event) ->
    pathname = window.location.pathname
    target = if event.target.tagName == "A" then event.target else event.target.closest("a")
    targetElementHref = target.getAttribute("href")
    # check if href only contains hash or check if current pathname matches with pathname in href
    # when true, prevent default and scroll into view
    if targetElementHref.split("#")[0] == pathname || targetElementHref.split("#")[0] == ""
      # prevent error logs in cases above is true but there is no hash present in href
      if targetElementHref.includes("#")
        event.preventDefault()
        targetElementId = targetElementHref.split("#")[1].toLowerCase()
        targetElement = document.getElementById("#{targetElementId}")

        webModulePadding = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("--web-module-padding")) * parseFloat(getComputedStyle(document.documentElement).fontSize)
        verticalOffset = webModulePadding * -3

        animateScrollTo(targetElement, {
                          cancelOnUserAction: false,
                          verticalOffset: verticalOffset,
                          speed: 250
                       })
