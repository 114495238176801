import { default as WebModule__Header } from 'models/modulor/web_module/header'
import styles from "./modal_header_module.module.css.json"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export class ModalHeaderModule extends WebModule__Header
  @defaults =
    debug: false
    name: 'ModalHeaderModule'

  @selector: ".modal_header_module"

  @property "height",
    get: -> @$element.outerHeight()

  on_init: ->
    @set_height_var()

  on_destroy: ->
    @remove_height_var()

  on_resize: -> @set_height_var()

  set_height_var: -> @$element.css("--height", "#{@height}px")
  remove_height_var: -> @$element.css("--height", null)

ModalHeaderModule.register()
