import { Controller } from "stimulus"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends Controller
  @targets = ["audio"]

  @property "duration",
    get: -> Number @data.get("duration")

  onTimeUpdate: (e) ->
    audio = e.target
    percentage = audio.currentTime / @duration * 100
    @element.style.setProperty("--playlist-item-bar-width", "#{percentage.toFixed(2)}%");

  onClickProgressBar: (e) ->
    @setCurrentTimeOnClick(e)

  setCurrentTimeOnClick: (e) ->
    elementLeft = e.target.getBoundingClientRect().left
    elementWidth = e.target.getBoundingClientRect().width
    clickPosition = e.clientX - elementLeft
    percentage = clickPosition/elementWidth
    currentTime = @duration*percentage

    @audioTarget.currentTime = currentTime
