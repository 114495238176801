import { CollapsibleController } from "./collapsible_controller"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock"

export default class extends CollapsibleController {
  static targets = ["scrollContainer"]

  toggle() {
    super.toggle()

    this.collapsibleSectionTarget.classList.remove("transitioned")

    if (this.expanded) {
      disableBodyScroll(this.scrollContainerTarget)
    } else {
      clearAllBodyScrollLocks()
    }

    this.openClasses.forEach(klass =>
      $(this.element).toggleClass(klass, this.expanded)
    )
  }

  handleMenuTransitionend(e) {
    this.collapsibleSectionTarget.classList.add("transitioned")
  }

  handleKeyUp(e) {
    if (e.key === "Escape") {
      super.toggle(true)

      this.openClasses.forEach(klass => this.element.classList.remove(klass))
    }
  }

  get animateHeight() {
    return false
  }

  get openClasses() {
    return this.data.get("openClass").split(/\s+/)
  }
}
