import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tick"]

  connect() {
    if (!this.hasTickTarget) return
    this.start()
  }

  start() {
    this.showCurrentTick()
    if (!this.hasTickTargets) return
    this._setTimeout()
  }

  pause() {
    this._clearTimeout()
  }

  next() {
    this.index++
    this._setTimeout()
  }

  hasTickTargets() {
    return this.hasTickTarget && this.tickTargets.length > 1
  }

  showCurrentTick() {
    this.tickTargets.forEach((tick, index) => {
      tick.classList.toggle(this.currentClass, index == this.index)
    })
  }

  _setTimeout() {
    this._timeout = window.setTimeout(
      () => this.next(),
      this.currentTickTimeout
    )
  }

  _clearTimeout() {
    if (this._timeout) {
      clearTimeout(this._timeout)
    }
  }

  get currentTick() {
    return this.tickTargets[this.index]
  }

  get currentClass() {
    return this.data.get("currentClass")
  }

  get currentTickTimeout() {
    return this.currentTick.dataset.interval
  }

  get index() {
    return parseInt(this.data.get("index")) || 0
  }

  set index(value) {
    if (value > this.lastIndex) {
      value = 0
    } else if (value < 0) {
      value = this.lastIndex
    }
    this.data.set("index", value)
    this.showCurrentTick()
  }

  get lastIndex() {
    if (!this.hasTickTarget) return
    return this.tickTargets.length - 1
  }
}
