import { Controller } from "stimulus"
import Flickity from "flickity"

Flickity.prototype._createResizedClass = function() {
  this.element.classList.add("flickity-resized")
}

Flickity.createMethods.push("_createResizedClass")

const resize = Flickity.prototype.resize

Flickity.prototype.resize = function() {
  this.element.classList.remove("flickity-resized")
  resize.call(this)
  this.element.classList.add("flickity-resized")
}

export class CarouselController extends Controller {
  static targets = ["cells"]

  initialize() {
    if (!this.hasCellsTarget) return

    this.flickity = new Flickity(this.cellsTarget, {
      adaptiveHeight: this.adaptiveHeight,
      autoPlay: this.autoPlay,
      cellSelector: this.cellSelector,
      groupCells: this.groupCells,
      cellAlign: this.cellAlign,
      initialIndex: this.initialIndex,
      pageDots: this.pageDots,
      prevNextButtons: this.prevNextButtons,
      percentPosition: this.percentPosition,
      accessibility: false
    })

    this.flickity.on("ready", this.resize())

    this.handleFlickityChange = this.onFlickityChange.bind(this)
    this.flickity.on("change", this.handleFlickityChange)
  }

  connect() {
    this.setFirstLastSlideClass()
    this.setNumberOfCellsData()
    this.resize()

    setTimeout(() => {
      this.resize()
    }, 150)
  }

  disconnect() {
    if (!this.flickity) return
    this.flickity.destroy()
  }

  onFlickityChange(event) {
    this.setFirstLastSlideClass()
    this.dispatchChangeEvent()
  }

  dispatchChangeEvent() {
    const event = new CustomEvent("carouselChange", {
      bubbles: true
    })
    this.element.dispatchEvent(event)
  }

  resize() {
    if (this.flickity) {
      this.flickity.resize()
      this.setFirstLastSlideClass()
    }
  }

  previous() {
    if (!this.flickity) return
    this.flickity.previous()
  }

  next() {
    if (!this.flickity) return
    this.flickity.next()
  }

  setNumberOfCellsData() {
    if (!this.flickity) return
    this.element.dataset.numberOfCells = this.flickity.cells.length
  }

  setFirstLastSlideClass() {
    this.element.classList.toggle("first-slide", this.isFirstIndex)
    this.element.classList.toggle("last-slide", this.isLastIndex)
  }

  get isLastIndex() {
    if (!this.flickity) return
    return this.flickity.selectedIndex === this.flickity.slides.length - 1
  }

  get isFirstIndex() {
    if (!this.flickity) return
    return this.flickity.selectedIndex === 0
  }

  // Flickity options

  get adaptiveHeight() {
    return this.data.get("adaptiveHeight") === "true"
  }

  get autoPlay() {
    if (this.data.get("autoPlay") === "true") {
      return 12000
    } else {
      return false
    }
  }

  get cellAlign() {
    return this.data.get("cellAlign") || "left"
  }

  get cellSelector() {
    if (!this.data.get("cellSelector")) return
    return `.${this.data
      .get("cellSelector")
      .split(/\s+/)
      .join(".")}`
  }

  get initialIndex() {
    return this.data.get("initialIndex") || 0
  }

  get groupCells() {
    return this.data.get("groupCells") === "true"
  }

  get percentPosition() {
    return false
  }
}
