import { Controller } from "stimulus"
import scrollIntoView from "scroll-into-view-if-needed"

export class CollapsibleController extends Controller {
  static targets = ["collapsibleButton", "collapsibleSection"]

  connect() {
    if (!this.animateHeight) return
    this.toggleSectionHeight(this.expanded)
    this.toggleBasedOnHashInUrl()
  }

  disconnect() {
    this.close()
  }

  handleClick(e) {
    this.toggle()
    this.toggleHashInUrl(this.expanded)
  }

  handlePopstate(e) {
    if (!this.permalink) return
    const shouldExpand = (this.permalink == window.location.hash.substr(1))
    this.toggle(!shouldExpand)
  }

  toggle(expanded = this.expanded) {
    const expanding = !expanded
    const hiding = expanded
    this.collapsibleButtonTarget.setAttribute("aria-expanded", expanding)
    this.collapsibleSectionTarget.setAttribute("aria-hidden", hiding)

    if (this.animateHeight) {
      this.toggleSectionHeight(expanding)
    }

    if (expanding && this.shouldCollapseSiblings) {
      this.collapseSiblings()
    }
  }

  toggleHashInUrl(isExpanded) {
    if (!this.permalink) return
    if (isExpanded) {
      history.pushState({}, "", "#"+this.permalink)
    } else {
      history.pushState({}, "", window.location.pathname)
    }
  }

  toggleBasedOnHashInUrl() {
    if (!this.permalink) return
    const shouldExpand = (this.permalink == window.location.hash.substr(1))
    setTimeout(() =>
      {
        this.toggle(!shouldExpand)
        if (shouldExpand) { this.scrollToSelf() }
      }, 1000
    )
  }

  scrollToSelf() {
    scrollIntoView(this.element, {
      behavior: "smooth",
      scrollMode: "if-needed",
      skipOverflowHiddenElements: true
    })
  }

  close() {
    this.collapsibleButtonTarget.setAttribute("aria-expanded", false)
    this.collapsibleSectionTarget.setAttribute("aria-hidden", true)

    if (this.animateHeight) {
      this.toggleSectionHeight(false)
    }
  }

  collapseSiblings() {
    this.siblingControllers.forEach(siblingController => {
      if (siblingController && siblingController.expanded) {
        siblingController.toggle()
      }
    })
  }

  toggleSectionHeight(expand) {
    this.sectionHeight = this.getSectionHeight(expand)
  }

  getSectionHeight(expand) {
    if (expand) {
      return this.collapsibleSectionTarget.scrollHeight
    } else {
      return 0
    }
  }

  set sectionHeight(height) {
    this.collapsibleSectionTarget.style.height = `${height}px`
  }

  get expanded() {
    return (
      this.collapsibleButtonTarget.getAttribute("aria-expanded") === "true" ||
      false
    )
  }

  get siblingControllers() {
    return [...this.siblings].map(sibling => {
      return this.application.getControllerForElementAndIdentifier(
        sibling,
        this.identifier
      )
    })
  }

  get siblings() {
    let siblings = []
    let sib = this.element.parentNode.firstChild
    while (sib) {
      if (sib.nodeType === 1 && sib !== this.element) {
        siblings.push(sib)
      }
      sib = sib.nextSibling
    }
    return siblings
  }

  get shouldCollapseSiblings() {
    return this.data.get("collapseSiblings") === "true" || false
  }

  get animateHeight() {
    return this.data.get("animateHeight") === "true" || true
  }

  get permalink() {
    return this.data.get("permalink")
  }
}
