import { Controller } from "stimulus"

export default class extends Controller
  connect: ->

  onClick: (e) ->
    e.preventDefault()
    return unless location = document.location
    return unless pathname = location.pathname

    current_locale = document.documentElement.lang

    other_locale = switch current_locale
                   when "en" then "nl"
                   when "nl" then "en"

    chunks = pathname.split('/')
    path_without_current_locale = switch chunks.includes(current_locale)
                                    when true then chunks.splice(2)
                                    else chunks.splice(1)

    new_path = [location.origin, other_locale, ...path_without_current_locale].filter(Boolean).join("/")
    window.location.assign(new_path)
