import ApplicationController from "./application_controller.coffee"
import debounce from "lodash/debounce"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends ApplicationController
  @targets = ["image"]

  @property "documentHeight",
    get: -> document.documentElement.scrollHeight

  @property "documentScrollPosition",
    get: -> document.documentElement.scrollTop + (window.innerHeight / 2)

  @property "scrollStepSize",
    get: -> @documentHeight / @imageTargets.length

  connect: ->
    @onScrollHandler = debounce(@onScroll, 50).bind(@)
    window.addEventListener("scroll", @onScrollHandler)
    @setCurrentScrollStep()

  disconnect: ->
    if @onScrollHandler
      window.removeEventListener("scroll", @onScrollHandler)

  onScroll: (e) ->
    @setCurrentScrollStep()

  setCurrentScrollStep: ->
    for scrollStep in [0...@imageTargets.length]
      rangeStart = scrollStep * @scrollStepSize
      rangeEnd = (scrollStep + 1) * @scrollStepSize
      if @documentScrollPosition >= rangeStart && @documentScrollPosition <= rangeEnd
        @imageTargets[scrollStep].setAttribute("data-active", true)
      else
        @imageTargets[scrollStep].removeAttribute("data-active")
