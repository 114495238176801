import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["legend"]

  toggleChaptersForRange(from, to) {
    if (!this.legendControllers) return
    this.legendControllers.forEach(legendController => {
      legendController.toggle(legendController.overlapsRange(from, to))
    })
  }

  get currentDate() {
    const dateFromHash = Date.parse(window.location.hash.replace(/\#/, ""))
    if (dateFromHash) {
      return dateFromHash
    } else {
      Date.now()
    }
  }

  get legendControllers() {
    return this.legendTargets.map(legend => {
      return this.application.getControllerForElementAndIdentifier(
        legend,
        "chapters--legend-component"
      )
    })
  }
}
