import ApplicationController from "./application_controller.coffee"
import debounce from "lodash/debounce"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends ApplicationController
  @targets = ["waypoint", "element"]

  @property "waypointTopPosition",
    get: ->
      if @hasWaypointTarget
        paddingTop = parseInt(window.getComputedStyle(@waypointTarget, null).getPropertyValue('padding-top'), 10)
        @waypointTarget.getBoundingClientRect().top + (paddingTop * .85)

  @property "documentScrollPosition",
    get: -> document.documentElement.scrollTop

  connect: ->
    @onScrollHandler = @onScroll.bind(@)
    window.addEventListener("scroll", @onScrollHandler)
    @clipElements()

  disconnect: ->
    if @onScrollHandler
      window.removeEventListener("scroll", @onScrollHandler)

  onScroll: (e) ->
    @toggleElements()

  toggleElements: ->
    if @hasElementTarget
      @clipElements()

  clipElements: ->
    for element in @elementTargets
      if @waypointTopPosition < 0
        element.hidden = true
        @playPauseVideos(false)
      else if @waypointTopPosition > 0 && element.hidden == true
        element.hidden = false
        @playPauseVideos(true)

  playPauseVideos: (bool) ->
    for element in @elementTargets
      for video in element.querySelectorAll('video')
        if bool == true
          video.play()
        else
          video.pause()
