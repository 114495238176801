import { Controller } from "stimulus"
import { areRangesOverlapping, parse, startOfDay, endOfDay } from "date-fns"

export default class extends Controller {
  connect() {}

  toggle(hide) {
    this.element.classList.toggle(this.hiddenClass, !hide)
  }

  overlapsRange(from, to) {
    return areRangesOverlapping(
      startOfDay(from),
      endOfDay(to),
      startOfDay(this.from),
      endOfDay(this.to)
    )
  }

  get from() {
    const timeElement = this.getTimeElement("from")
    if (!timeElement) return
    return parse(timeElement.getAttribute("datetime"))
  }

  get to() {
    const timeElement = this.getTimeElement("to")
    if (!timeElement) return
    return parse(timeElement.getAttribute("datetime"))
  }

  getTimeElement(fromOrTo) {
    return this.element.querySelector(`.date.${fromOrTo}`)
  }

  get hiddenClass() {
    return this.data.get("hiddenClass")
  }
}
