import ApplicationController from "./application_controller.coffee"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends ApplicationController
  @targets = ["target", "trigger"]

  connect: ->
    if @hasTriggerTarget && @hasTargetTarget || @hasTriggerTarget && @hasTargetTargets
      @onIntersectionHandler = @onIntersection.bind(@)
      @observer = new IntersectionObserver(@onIntersectionHandler, { threshold: 0, rootMargin: '0% 0% -100% 0%' })
      @observer.observe(@triggerTarget)

  onIntersection: (entries, observer) ->
    for entry in entries
      for target in @targetTargets
        target.toggleAttribute "data-text-black", entry.isIntersecting
