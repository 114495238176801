import { Controller } from "stimulus"

import debounce from "lodash/debounce"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends Controller
  @targets = ["results"]

  @property "urlValue",
    get: -> @element.getAttribute("data-#{@identifier}-url-value")

  connect: ->
    @onSearch = debounce(@onSearch, 100).bind(@)

  onSearch: (event) ->
    query = event.target.value
    @xhr.abort() if @xhr
    @fetchResults(query)

  fetchResults: (query) ->
    @xhr = $.ajax
            type: "GET"
            url: @urlValue
            data: { query: query }
            success: (data, status, xhr) =>
              @resultsTarget.innerHTML = data
