import Plugin from 'lib/modulor/plugin'

class TurbolinksProgressBar extends Plugin
  @defaults =
    debug: false
    name: 'TurbolinksProgressBar'

  @selector: '.turbolinks-progress-bar'

  on_init: ->
    @toggle_chapter_class()

  on_destroy: ->
    @$element.removeClass("is_chapter_page")

  is_chapter_page: -> @get_page_type() == 'chapter_page'

  get_page_type: -> $('body').data('page-type')

  toggle_chapter_class: -> @$element.toggleClass("is_chapter_page", @is_chapter_page())

TurbolinksProgressBar.register()
