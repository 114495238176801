import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["video"]

  initialize() {
    if (this.hasVideoTarget) {
      this.videoTarget.load()
    }
  }

  connect() {
    if (this.hasVideoTarget) {
      if (this.autoplay) {
        this.play()
      }
    }
  }

  disconnect() {}

  play() {
    if (this.videoTarget === undefined) return
    this.videoTarget.play()
  }

  stop() {
    if (this.videoTarget === undefined) return
    this.videoTarget.stop()
  }

  onEnded(e) {
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("video-ended", true, true, null)
    this.element.dispatchEvent(event)
  }

  onPlay(e) {
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("video-play", true, true, null)
    this.element.dispatchEvent(event)
  }

  onCanplay(e) {
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("video-canplay", true, true, null)
    this.element.dispatchEvent(event)
  }

  onLoadedData(e) {
    const event = document.createEvent("CustomEvent")
    event.initCustomEvent("video-loadeddata", true, true, null)
    if (this.autoplay && this.canPlay) {
      this.play()
    }
  }

  onTimeupdate(e) {
    const timeLeft = this.duration - this.currentTime
    const event = new CustomEvent("video-timeupdate", {
      detail: { timeLeft: timeLeft },
      bubbles: true,
      target: this.element
    })
    this.element.dispatchEvent(event)
  }

  onSuspend(e) {
    this.element.addAttribute("data-suspended", true)
  }

  get currentTime() {
    return this.videoTarget.currentTime
  }

  get duration() {
    return this.videoTarget.duration
  }

  get readyState() {
    return this.videoTarget.readyState
  }

  get canPlay() {
    return this.readyState >= 2
  }

  get autoplay() {
    this.data.get("autoplay") === "true"
  }
}
