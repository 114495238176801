import { Controller } from "stimulus"

export default class extends Controller {
  add(event) {
    let highlightId = event.currentTarget.dataset.highlightId
    this.elementsWithHighlightId(highlightId).forEach((element) =>
      element.classList.add(this.highlightClass))
  }

  remove(event) {
    let highlightId = event.currentTarget.dataset.highlightId
    this.elementsWithHighlightId(highlightId).forEach((element) =>
      element.classList.remove(this.highlightClass))
  }

  elementsWithHighlightId(highlightId) {
    return this.element.querySelectorAll(`[data-highlight-id="${highlightId}"]`)
  }

  get highlightClass() {
    return this.data.get("highlightClass")
  }
}
