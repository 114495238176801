import { CarouselController } from "./carousel_controller"
import weekStyles from "components/calendar_component/week_component.module.scss.json"

export default class extends CarouselController {
  static targets = ["week"]

  connect() {
    if (this.hash) {
      this.handleFlickityChange = this.onFlickityChange.bind(this)
      this.flickity.on("change", this.handleFlickityChange)
    }

    this.selectWeekByCurrentHash()

    super.connect()
  }

  disconnect() {
    super.disconnect()
  }

  onHashChange(event) {
    if (!this.hash) return
    this.selectWeekByCurrentHash()
  }

  onFlickityChange(event) {
    super.onFlickityChange(event)

    if (!this.hash) return
    this.updateLocationHash()
  }

  updateLocationHash() {
    const hash = `#${this.flickity.selectedElement.dataset.date}`
    history.replaceState(
      {
        url: `${window.location.pathname}${hash}`,
        modal: true,
        previousUrl: window.location.pathname
      },
      "",
      hash
    )
    window.dispatchEvent(new HashChangeEvent("hashchange"))
  }

  selectWeekByCurrentHash(event) {
    if (!this.flickity || !window.location.hash) return
    const weekForHash = this.getWeekForHash(window.location.hash)
    if (weekForHash === this.flickity.selectedElement) return
    const index = Array.prototype.indexOf.call(this.weekTargets, weekForHash)
    if (this.flickity.selectedIndex != index) {
      this.flickity.selectCell(index)
    }
  }

  getWeekForHash(hash) {
    const date = hash.replace(/\#/, "")
    return this.element.querySelector(`[data-date="${date}"]`)
  }

  get hash() {
    return this.data.get("hash") === "true"
  }

  get initialIndex() {
    if (this.currentOrUpcomingWeek) {
      return this.weekTargets.indexOf(this.currentOrUpcomingWeek)
    } else {
      return this.weekTargets.length - 1
    }
  }

  get currentOrUpcomingWeek() {
    return this.weekTargets.find(element => {
      return !element.classList.contains(this.pastClass)
    })
  }

  get pastClass() {
    return weekStyles.past.split(/\s+/)[0]
  }

  get isSafari() {
    return navigator.userAgent.indexOf("Safari") > -1
  }

  get percentPosition() {
    return false
  }
}
