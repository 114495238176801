import { Controller } from "stimulus"
import styles from "components/modal_component.module.scss.json"
import close_button_component_styles from "components/modal_component/close_button_component.module.scss.json"
import Turbolinks from "turbolinks"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends Controller
  @property "currentPage",
    get: -> document.querySelector(".modulor_page__is_current")

  @property "modalPage",
    get: -> document.querySelector(".modal_page")

  @property "modalComponent",
    get: -> document.querySelector(".#{styles.root}")

  @property "currentPath",
    get: -> window.location.pathname

  @property "userSignedIn",
    get: -> document.body.classList.contains("user_signed_in")

  connect: ->
    window.addEventListener("popstate", @onPopState) unless @userSignedIn

  disconnect: ->
    window.removeEventListener "popstate", @onPopState

  onPopState: (e) =>
    return unless e.state
    return unless e.state.url && e.state.previousUrl
    if e.state.modal
      @loadModal(e.state.url, e.state.previousUrl)
    else
      @closeModal(e.state.url, e.state.previousUrl)

  onOpen: (e) ->
    e.preventDefault()
    url = e.currentTarget.getAttribute("href")
    if @userSignedIn
      Turbolinks.visit(url)
    else
      history.pushState({ url: url, modal: true, previousUrl: @currentPath }, "", url)
      @loadModal(url, @currentPath)

  onClose: (e) ->
    e.preventDefault()
    url = e.currentTarget.getAttribute("data-close-path")
    if @userSignedIn
      Turbolinks.visit(url)
    else
      history.pushState({ url: url, modal: false, previousUrl: @currentPath }, "", url)
      @closeModal(url)

  handleKeyUp: (e) ->
    if e.key == "Escape"
      $closeButton = $(@modalComponent).find("." + close_button_component_styles.root.split(" ")[0])
      return unless $closeButton.length > 0
      url = $closeButton[0].getAttribute("data-close-path")
      if @userSignedIn
        Turbolinks.visit(url)
      else
        history.pushState({ url: url, modal: false, previousUrl: @currentPath }, "", url)
        @closeModal(url)


  loadModal: (url, previousUrl = @currentPath) ->
    @showProgressBar()
    $.ajax
      type: "GET"
      url: url
      headers: { "modulor-skip-layout": true, "modal-only": true }
      success: (data, status, xhr) =>
        @hideProgressBar()
        dataDoc = new DOMParser().parseFromString(data, "text/html");
        loadedCurrentPage = dataDoc.querySelector(".modulor_page__is_current")
        modal = dataDoc.body.querySelector(".#{styles.root}")
        if modal
          @addModal(modal)
          @updateBodyAttrs(loadedCurrentPage)
          @updateHtmlTitle(loadedCurrentPage)
        else
          # console.warn "Could not find a modal in the response:"
          # console.warn dataDoc.body

  closeModal: (url) ->
    modalComponent = @modalComponent
    currentPage = document.querySelector(".modulor_page[data-path='#{url.substr(4)}']")
    currentPage.classList.add("modulor_page__is_current")
    @updateBodyAttrs(currentPage)
    @updateHtmlTitle(currentPage)
    modalComponent.remove()

  addModal: (modal) ->
    if @modalComponent
      document.body.replaceChild(modal, @modalComponent)
    else
      document.body.appendChild(modal)

  updateBodyAttrs: (page) ->
    for name in ["data-path", "data-page-type"]
      document.body.setAttribute(name, page.getAttribute(name))

  updateHtmlTitle: (page) ->
    document.head.querySelector("title").innerText = page.getAttribute("data-html-title")

  showProgressBar: ->
    Turbolinks.controller.adapter.progressBar.setValue(0)
    Turbolinks.controller.adapter.progressBar.show()

  hideProgressBar: ->
    Turbolinks.controller.adapter.progressBar.setValue(1)
    Turbolinks.controller.adapter.progressBar.hide()
