import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["iframe"]

  onMessage(event) {
    if (!event.data) return
    if (!event.data.height) return
    if (!this.iframeTarget) return
    if (!this.iframeTarget.src.includes(event.data.identifier)) return

    this.iframeTarget.style.height = `${event.data.height}px`
  }
}
