import Plugin from 'lib/modulor/plugin'

class ExternalLink extends Plugin
  @defaults =
    debug: false
    name: 'ExternalLink'

  @selector: 'a[href^="http"]'

  on_init: ->
    @$element.attr "target", "_blank"

  on_destroy: ->

ExternalLink.register()
