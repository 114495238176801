import { Controller } from "stimulus"

Function::property = (prop, desc) ->
  Object.defineProperty @prototype, prop, desc

export default class extends Controller
  dispatch: (eventName, { target = @element, detail = {}, bubbles = true, cancelable = true } = {}) ->
    type = "#{@identifier}:#{eventName}"
    event = new CustomEvent(type, { detail, bubbles, cancelable })
    target.dispatchEvent(event)
    event

  observeMutations: (callback, target = @element, options = { childList: true, subtree: true }) ->
    observer = new MutationObserver((mutations) =>
      observer.disconnect()
      Promise.resolve().then(start)
      callback.call(@, mutations)
    )
    start = -> observer.observe(target, options) if target.isConnected
    start()
    observer
