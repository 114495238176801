# document.addEventListener "turbolinks:load", (e) =>
#
#   setTimeout (->
#
#     if (window.location.hash)
#       hash = window.location.hash
#
#       console.log $(hash).offset().top
#
#       console.log $(hash).offset().top
#
#       $('html, body').animate({
#         scrollTop: $(hash).offset().top
#       }, 1500, 'swing')
#
#   ), 1000
