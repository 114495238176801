import { Controller } from "stimulus"
import { parse } from "date-fns"
import dateQueries from "date-queries"

export default class extends Controller {
  connect() {
    for (let { query, label, dtstart, dtend } of this.queries) {
      dtstart = parse(dtstart)
      if (dtend) {
        dtend = parse(dtend)
      }

      let result = dateQueries(dtstart, dtend, [query])

      if (result) {
        this.element.innerHTML = label
        break
      }
    }
  }

  disconnect() {
    this.element.innerHTML = ""
  }

  get queries() {
    return JSON.parse(this.data.get("queries"))
  }
}
