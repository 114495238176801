persistScroll = ->
  scrollPosition = null
  enabled = true

  document.addEventListener 'turbolinks:before-visit', (event) ->
    if (enabled)
      scrollPosition = window.scrollY
    else
      scrollPosition = null
    enabled = false

  document.addEventListener 'turbolinks:load', (event) ->
    elements = document.querySelectorAll("[data-turbolinks-persist-scroll='true']")

    for element in elements
      element.addEventListener 'click', ->
        enabled = true

    if scrollPosition && !$('body').hasClass('user_signed_in')
      window.scrollTo(0, scrollPosition)
    else
      window.scrollTo(0, 0)
      scrollPosition = null

persistScroll()
