import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    Promise.resolve().then(() => {
      this.toggleChapterLegendsForCurrentlyVisibleDateRange()
    })
  }

  onCarouselChange(event) {
    this.toggleChapterLegendsForCurrentlyVisibleDateRange()
  }

  toggleChapterLegendsForCurrentlyVisibleDateRange() {
    if (!this.datePickerController) return
    if (!this.chaptersLegendListController) return
    let [from, to] = this.datePickerController.currentlyVisibleDateRange
    this.chaptersLegendListController.toggleChaptersForRange(from, to)
  }

  get chaptersLegendListController() {
    if (!this.chaptersLegendListComponent) return
    return this.application.getControllerForElementAndIdentifier(
      this.chaptersLegendListComponent,
      "chapters--legend-list-component"
    )
  }

  get datePickerController() {
    if (!this.datePickerComponent) return
    return this.application.getControllerForElementAndIdentifier(
      this.datePickerComponent,
      "date-picker-component"
    )
  }

  get chaptersLegendListComponent() {
    const selector = "[data-controller='chapters--legend-list-component']"
    return this.element.querySelector(selector)
  }

  get datePickerComponent() {
    const selector = "[data-controller='date-picker-component']"
    return this.element.querySelector(selector)
  }
}
